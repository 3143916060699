import React from "react";
import css from './contactInfo.module.scss';
import {useTranslation} from "react-i18next";
import MapDesktopImage from "../../../../../images/Contact/map_desktop.svg"
import Map1MobileImage from "../../../../../images/Contact/map_1_mobile.svg"
import Map2MobileImage from "../../../../../images/Contact/map_2_mobile.svg"
import CompanyContactMail from "../../../../shared/CompanyContactMail/CompanyContactMail";
import CompanySalesMail from "../../../../shared/CompanySalesMail/CompanySalesMail";
import CompanySupportMail from "../../../../shared/CompanySupportMail/CompanySupportMail";
import BoxHeader from "../../../../shared/BoxHeader/BoxHeader";
import CompanyButton from "../../../../shared/CompanyButton/CompanyButton";

const ContactInfo = () => {
  const { t } = useTranslation();

  const button = <CompanyButton version={"black"} height={'20px'} />;

  return (
    <div className={css.wrapper}>
      <BoxHeader lineColor={'green'} headerType={'h2'}>Contact</BoxHeader>
      <div className={css.contentWrapper}>
        <img src={MapDesktopImage} alt={"deeplai contact map"} className={css.mapDesktopImage} />
        <img src={Map2MobileImage} alt={"deeplai contact map"} className={css.map2MobileImage} />
        <img src={Map1MobileImage} alt={"deeplai contact map"} className={css.map1MobileImage} />
        <div className={[css.textWrapper, css.textPolandWrapper].join(' ')}>
            <div className={css.text}>
                {button}
                <div className={css.textHeader}>
                    <div className={css.textHeaderInner}>Main Office</div>
                </div>
                <p>
                  <span>Al. Kraśnicka 31</span><br/>
                  <span>20-718 Lublin</span><br/>
                  <span>Polska</span>
                </p>
                <p>
                  <span>
                    Phone: +48 81 743 51 09
                  </span>
                </p>
            </div>
        </div>
        <div className={[css.textWrapper, css.textCanadaWrapper].join(' ')}>
            <div className={css.text}>
                {button}
                <div className={css.textHeader}>
                    <div className={css.textHeaderInner}>Canada</div>
                </div>
                <p>
                  <span>702-1641 Lonsdale Ave</span><br/>
                  <span>North Vancouver, BC</span><br/>
                  <span>V7M 2J5</span><br/>
                  <span>Canada</span>
                </p>
                <p>
                  <span>
                    Phone: +1 778 819 3279
                  </span>
                </p>
            </div>
        </div>
      </div>
      <div className={css.mailsWrapper}>
        <div className={css.mailWrapper}>Email: <CompanyContactMail color={'green'} /></div>
        <div className={css.mailWrapper}>Sales: <CompanySalesMail color={'green'} /></div>
        <div className={css.mailWrapper}>Tech-support: <CompanySupportMail color={'green'} /></div>
      </div>
      <div className={css.footerWrapper}>
          District Court for Lublin-Wschód in Lublin with office in Świdnik (VI Commercial Department of the Court Register) | KRS: 0000803809 | NIP / VAT ID: 9462691664 | The share capital of the Company (fully paid): 5000 PLN
      </div>
    </div>
  )
};

export default ContactInfo;
